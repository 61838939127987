import { searchAndInitialize } from "../Utils"
import anime from "animejs"
import DomElement from "../DomElement"
import * as Dom from "../DomFunctions"

const QUERY_TOGGLE = ".accordion__toggle"
const QUERY_OPEN_SECTION = ".accordion__item.is-open"
const QUERY_COLLAPSE = ".accordion__collapse"

const CLASS_ITEM = "accordion__item"
const CLASS_OPEN = "is-open"
const CLASS_KEEP_OPEN = "accordion__keep-open"

const REGEX_HIDDEN = /accordion--hidden-.*/

const ANIMATION_OPEN = 300

/**
 * The Accordion component
 */
class Accordion extends DomElement {
  private _sectionClickHandler: (e: Event) => void
  private _hiddenIndicator!: HTMLInputElement

  /**
   * Creates and initializes the Accordion component.
   * @param {DomElement} - The root element of the Accordion component.
   */
  constructor(element: Element) {
    super(element)

    this._sectionClickHandler = this._handleSectionClick.bind(this)
    this._initialize()
  }

  /**
   * Initializes the Accordion component.
   * @private
   */
  protected _initialize() {
    if (this.element.className.split(" ").some((c) => REGEX_HIDDEN.test(c))) {
      let indicator = new DomElement<HTMLInputElement>("input")
        .setAttribute("type", "hidden")
        .addClass("js-hidden")

      this.appendChild(indicator)
      this._hiddenIndicator = indicator.element
    }

    for (let toggle of this.element.querySelectorAll(QUERY_TOGGLE)) {
      toggle.addEventListener("click", this._sectionClickHandler)
    }
  }

  protected _handleSectionClick(event: Event) {
    if (this._hiddenIndicator) {
      let style = window.getComputedStyle(this._hiddenIndicator)

      if (style.visibility !== "visible") {
        return
      }
    }

    let navSection = (event.target as HTMLElement).parentElement!

    while (!Dom.hasClass(navSection, CLASS_ITEM) && navSection.parentElement) {
      navSection = navSection.parentElement
    }

    let prevSection = this.element.querySelector(QUERY_OPEN_SECTION)

    if (prevSection && prevSection !== navSection) {
      if (!Dom.hasClass(this.element, CLASS_KEEP_OPEN)){
        this._toggleSection(prevSection)
      }
    }

    this._toggleSection(navSection)
  }

  protected _toggleSection(accSection: Element) {
    let collapseElement = accSection.querySelector(QUERY_COLLAPSE)! as HTMLElement

    if (Dom.hasClass(accSection, CLASS_OPEN)) {
      Dom.removeClass(accSection, CLASS_OPEN)
      this._closeCollapseSection(collapseElement)
    } else {
      Dom.addClass(accSection, CLASS_OPEN)
      if (collapseElement) { // to ignore the case when there is no collapsible element (see sdx doku navigation, "all the basics") in a list of accordion
        this._openCollapseSection(collapseElement)
      }
    }
  }

  protected _openCollapseSection(el: HTMLElement) {
    el.style.display = "block"

    anime({
      targets: el,
      duration: ANIMATION_OPEN,
      height: el.scrollHeight,
      opacity: 1,
      easing: "cubicBezier(0.550, 0.085, 0.320, 1)",
      complete: () => {
        el.setAttribute("aria-expanded", "true")
        el.classList.add(CLASS_OPEN)
        el.style.height = "auto" // allow to grow or shrink with content
      }
    })
  }

  protected _closeCollapseSection(el: HTMLElement) {
    // Can't animate "auto", therefore update to current height
    el.style.height = `${el.scrollHeight}px`

    anime({
      targets: el,
      duration: ANIMATION_OPEN,
      height: 0,
      opacity: 0,
      easing: "cubicBezier(0.550, 0.085, 0.320, 1)",
      complete: () => {
        el.setAttribute("aria-expanded", "false")
        el.style.removeProperty("display") // removes extra padding in Footer after opening and closing
        el.style.removeProperty("height") // SDX-691 Missing items in Footer after resizing
        el.style.removeProperty("opacity") // SDX-691 Missing items in Footer after resizing
        el.classList.remove(CLASS_OPEN)
      }
    })
  }

  /**
   * Removes all event handlers and clears references.
   */
  public destroy() {
    for (let toggle of this.element.querySelectorAll(QUERY_TOGGLE)) {
      toggle.removeEventListener("click", this._sectionClickHandler)
    }

    (this as any)._sectionClickHandler = null;
    (this as any).element = null
  }
}

export function init() {
  searchAndInitialize(".accordion", (e) => {
    new Accordion(e)
  })
}

export default Accordion
