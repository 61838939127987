import DomElement from "../DomElement"
import { searchAndInitialize } from "../Utils"
import { removeAllChildren, tryGetData, isColor, ChartData } from "./ChartFunctions"
import anime from "animejs"

const QUERY_CHART = ".js-chart"
const QUERY_LEGEND = ".js-legend"

const DASH_SEPARATOR_WIDTH = 3
const ANIMATION_DURATION = 1500
const ANIMATION_DURATION_LEGEND = 500

const QUERY_META_TITLE = ".meta .title"
const QUERY_META_SUBTITLE = ".meta .subtitle"

/**
 * Pie Chart Component.
 */
class PieChart extends DomElement<HTMLElement> {
  private _data!: ChartData

  private _chart!: HTMLElement
  private _legend!: HTMLElement
  private _title!: HTMLElement
  private _subtitle!: HTMLElement

  private _unit!: string
  private _alwaysShowLegend!: boolean

  /**
   * Creates and initializes the Pie Chart component.
   * @param {DomElement} - root element of the chart.
   * @param {Array} - pie chart data definitions.
   */
  constructor(element: HTMLElement, data?: ChartData) {
    super(element)
    if (data) {
      this._data = data
    }

    this._initialize()
  }

  protected _initialize() {
    this._chart = this.element.querySelector(QUERY_CHART)! as HTMLElement
    this._legend = this.element.querySelector(QUERY_LEGEND)! as HTMLElement
    this._title = this.element.querySelector(QUERY_META_TITLE)! as HTMLElement
    this._subtitle = this.element.querySelector(QUERY_META_SUBTITLE)! as HTMLElement

    this._unit = this.getAttribute("data-unit") || ""
    this._alwaysShowLegend = this.element.hasAttribute("data-always-show-legend")

    if (!this._data) {
      this._data = tryGetData(this.element)
    }

    this._render()
  }

  protected _render() {
    const total = this._data.reduce((a, b) => a + b.value, 0)
    const r = 16
    const dashTotal = (2 * r * Math.PI)

    let currentRotate = 9

    // Cleanup
    removeAllChildren(this._chart)

    if (this._legend) {
      removeAllChildren(this._legend)
    }

    let percentageAdjustTotal = 0
    let percentageAdjust = 0
    const separatorPercentage = DASH_SEPARATOR_WIDTH / 100

    for (let i = 0; i < this._data.length; i++) {
      const entry = this._data[i]

      const percentage = entry.value / total
      if (percentage < separatorPercentage) {
        percentageAdjustTotal += (separatorPercentage - percentage)
        percentageAdjust++
      }
    }

    if (percentageAdjust > 0) {
      percentageAdjust = percentageAdjustTotal / (this._data.length - percentageAdjust)
    }

    const animations = anime.timeline()

    let animationOffset = 0
    for (let i = 0; i < this._data.length; i++) {
      const entry = this._data[i]

      const displayPercentage = entry.value / total
      const percentage = Math.max(separatorPercentage, displayPercentage - percentageAdjust)

      let dashWidth = (percentage * dashTotal) - DASH_SEPARATOR_WIDTH

      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
      svg.setAttribute("viewBox", "0 0 34 34")
      svg.setAttribute("role", "img")
      svg.setAttribute("aria-labelledby", "title desc")

      let title = document.createElementNS("http://www.w3.org/2000/svg", "title")
      title.setAttribute("id", "title")
      title.textContent = `Pie chart segment ${Math.floor(displayPercentage * 100)}%`

      let description = document.createElementNS("http://www.w3.org/2000/svg", "desc")
      description.setAttribute("id", "desc")
      description.textContent = `${entry.title}: ${entry.value}`

      let circle = document.createElementNS("http://www.w3.org/2000/svg", "circle")
      circle.setAttribute("cx", "17")
      circle.setAttribute("cy", "17")
      circle.setAttribute("r", String(r))

      if (isColor(entry.color) === true) {
        circle.setAttribute("stroke", `${entry.color}`)
      } else {
        circle.setAttribute("class", entry.color)
      }

      circle.setAttribute("role", "presentation")
      svg.setAttribute("style", `transform: rotate(${currentRotate}deg);`)

      svg.appendChild(title)
      svg.appendChild(description)
      svg.appendChild(circle)
      this._chart.appendChild(svg)

      let animationDuration = ANIMATION_DURATION * percentage
      circle.style.display = "none"

      let counter = { var: 0.5 }
      animations.add({
        targets: counter,
        var: dashWidth,
        begin: () => {
          circle.style.display = ""
        },
        update: () => {
          circle.setAttribute("stroke-dasharray", `${counter.var} ${dashTotal}`)
        },
        duration: animationDuration,
        easing: "easeInQuint"
      })

      // Legend
      if (this._legend && (this._data.length > 1) || this._alwaysShowLegend) {
        let bullet = new DomElement("span")
          .addClass("bullet")

        if (isColor(entry.color) === true) {
          bullet.setAttribute("style", `background-color: ${entry.color}`)
        } else {
          bullet.addClass(entry.color)
        }

        const caption = new DomElement("span")
        const captionElement = caption.element as HTMLElement
        captionElement.innerText = entry.title

        let legendItem = new DomElement("li")
          .appendChild(bullet)
          .appendChild(caption)

        this._legend.appendChild(legendItem.element)

        this._animateLegend(legendItem.element as HTMLElement, animationOffset)
      }
      animationOffset += animationDuration

      currentRotate += (360 * percentage)

      if (i === this._data.length - 1) {
        this._title.textContent = `${entry.value} ${this._unit}`
        this._subtitle.textContent = entry.title
      }
    }
  }

  private _animateLegend(legendItem: HTMLElement, animationOffset: number) {
    legendItem.style.opacity = "0"
    anime({
      targets: legendItem,
      duration: ANIMATION_DURATION_LEGEND,
      opacity: 1,
      easing: "easeInOutQuint",
      delay: animationOffset,
      complete: () => {
        legendItem.style.removeProperty("opacity")
      }
    })
  }

  /**
   * Updates the pie chart with the specified data definitions.
   * @param {Array} - pie chart data definitions.
   */
  public update(data: ChartData) {
    if (data) {
      this._data = data
    }

    this._render()
  }

  /**
   * Removes all event handlers and clears references.
   */
  public destroy() {
    (this as any)._data = undefined;
    (this as any)._title = undefined;
    (this as any)._subtitle = undefined;
    (this as any)._unit = undefined

    removeAllChildren(this._chart);
    (this as any)._chart = undefined

    if (this._legend) {
      removeAllChildren(this._legend);
      (this as any)._legend = undefined
    }
  }

  /**
   * @deprecated use destroy() instead.
   * @todo remove in version 2.0.0
   */
  public destory() {
    this.destroy()
  }
}

export function init() {
  searchAndInitialize<HTMLElement>(".pie-chart", (e) => {
    new PieChart(e)
  })
}

export default PieChart
