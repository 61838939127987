import { searchAndInitialize, preventDefault } from "../Utils"
import { empty, addClass, removeClass, hasClass } from "../DomFunctions"
import DomElement from "../DomElement"
import * as Inputs from "../Inputs"

const QUERY_DROPDOWN = ".js-autocomplete"
const CLASS_RESULT = "autocomplete__result"
const CLASS_OPEN = "is-open"
const CLASS_HOVER = "js-hover"
const ATTRIBUTE_VALUE = "data-value"

const TIMEOUT_BLUR = 400

export interface Source {
  (
    term: string,
    callback: (matches: string[], termused: string) => void
  ): void
}

export interface AutocompleteConfig {
  minChars: number
  source: Source
}

/**
 * Autocomplete component
 * @fires Autocomplete#change
 */
class Autocomplete extends DomElement<HTMLElement> {
  private _source!: Source
  private _minChars!: number

  private _input: HTMLInputElement
  private _suggestionList!: HTMLUListElement
  private _dropdown: HTMLElement

  private _clickHandler: (event: MouseEvent) => void
  private _windowClickHandler: (event: MouseEvent) => void
  private _keyUpHandler: (event: KeyboardEvent) => void
  private _keyDownHandler: (event: KeyboardEvent) => void
  private _blurHandler: (event: Event) => void

  constructor(element: HTMLElement, configuration?: AutocompleteConfig) {
    super(element)

    this._input = this.element.querySelector("input")!
    this._dropdown = this.element.querySelector(QUERY_DROPDOWN)! as HTMLElement

    // Setup event context
    this._clickHandler = this._handleClick.bind(this)
    this._windowClickHandler = this._handleWindowClick.bind(this)
    this._keyUpHandler = this._handleKeyUp.bind(this)
    this._keyDownHandler = this._handleKeyDown.bind(this)
    this._blurHandler = this._handleBlur.bind(this)

    if (configuration) {
      this._minChars = configuration.minChars
      this._source = configuration.source
    }

    if (!this._minChars || this._minChars < 0) {
      this._minChars = 2
    }

    this._initialize()
  }

  /**
   * Initializes the Autocomplete component.
   * @private
   */
  protected _initialize() {
    this._clearSuggestions()

    if (this._input.getAttribute("disabled")) {
      this.disable()
    } else {
      this.enable()
    }

    // Disable browser autofill
    this._input.setAttribute("autocomplete", "off")
  }

  /**
   * The Autocomplete component configuration object
   * @callback Autocomplete~Suggest
   * @property {String} term - The current search term.
   * @property {String[]} matches - The list of matching strings.
   */

  /**
   * The Autocomplete component configuration object
   * @callback Autocomplete~Source
   * @property {String} term - The current search term.
   * @property {Autocomplete~Suggest} suggest - The autocomplete callback function to report the results.
   */

  /**
   * The Autocomplete component configuration object
   * @typedef {Object} Autocomplete~Config
   * @property {Number} minChars - The minimal required characters to start querying for autocomplete matches.
   * @property {Autocomplete~Source} source - The autocomplete source function.
   */

  /**
   * Updates the autocomplete component configuration for the current instance
   * @param {Autocomplete~Config} configuration The configuration object
   */
  public configure(configuration?: AutocompleteConfig) {
    if (!configuration) {
      return
    }

    if (configuration.minChars) {
      this._minChars = Math.min(configuration.minChars, 1)
    }

    if (configuration.source) {
      this._source = configuration.source
    }

    this._clearSuggestions()
  }

  /**
   * Sets the select control to the enabled state.
   */
  public enable() {
    if (!this._input) {
      return
    }

    this._input.removeAttribute("disabled")

    this._input.addEventListener("keyup", this._keyUpHandler)
    this._input.addEventListener("keydown", this._keyDownHandler)
    this._input.addEventListener("blur", this._blurHandler)
  }

  /**
   * Sets the select control to the disabled state.
   */
  public disable() {
    if (!this._input) {
      return
    }

    this._input.setAttribute("disabled", "true")

    this._input.removeEventListener("keyup", this._keyUpHandler)
    this._input.removeEventListener("keydown", this._keyDownHandler)
    this._input.removeEventListener("blur", this._blurHandler)

    this.close()
  }

  /**
   * Destroys the component and frees all references.
   */
  public destroy() {
    this.disable();

    (this as any)._keyUpHandler = undefined;
    (this as any)._keyDownHandler = undefined;
    (this as any)._windowClickHandler = undefined;
    (this as any)._blurHandler = undefined;

    (this as any)._input = undefined
  }

  /**
   * Closes the suggestions dropdown.
   */
  public open() {
    this._dropdown.addEventListener("click", this._clickHandler)
    window.addEventListener("click", this._windowClickHandler)

    this.addClass(CLASS_OPEN)
  }

  /**
   * Opens the suggestions dropdown.
   */
  public close() {
    this._dropdown.removeEventListener("click", this._clickHandler)
    window.removeEventListener("click", this._windowClickHandler)

    this.removeClass(CLASS_OPEN)
  }

  /**
   * Gets the value of the input field.
   * @returns {String} The value of the input field.
   */
  get value() {
    return this._input.value
  }

  protected _handleClick(event: MouseEvent) {
    if (!this._isDropdownTarget(event.target as Node)) {
      return
    }

    let current = event.target as HTMLElement
    while (current.nodeName !== "LI" && current.parentNode) {
      current = current.parentNode as HTMLElement
    }

    if (current.nodeName === "LI") {
      preventDefault(event)
      this._selectItem(current)
    }
  }

  protected _handleBlur() {
    setTimeout(() => {
      this.close()
    }, TIMEOUT_BLUR)
  }

  protected _handleKeyUp(evt: KeyboardEvent) {
    let keycode = evt.which || evt.keyCode

    if (Inputs.containsKey(keycode, [ Inputs.KEY_ARROW_UP, Inputs.KEY_ARROW_DOWN, Inputs.KEY_ENTER, Inputs.KEY_TAB ])) {
      // Do not handle these events on keyup
      preventDefault(evt)
      return
    }

    const target = evt.currentTarget as HTMLInputElement

    if (evt.currentTarget && target.value && target.value.length >= this._minChars) {
      this._getSuggestion(target.value)
    } else {
      this.close()
    }
  }

  protected _handleKeyDown(evt: KeyboardEvent) {
    let keycode = evt.which || evt.keyCode
    const isOpen = hasClass(this.element, CLASS_OPEN)

    if (keycode === Inputs.KEY_ESCAPE && isOpen === true) {
      // handle Escape key (ESC)
      this.close()
      preventDefault(evt)
      return
    }

    if (isOpen === true && Inputs.containsKey(keycode, [ Inputs.KEY_ENTER, Inputs.KEY_TAB ])) {
      let focusedElement = this._suggestionList.querySelector(`.${CLASS_HOVER}`)

      preventDefault(evt)
      this._selectItem(focusedElement)
      return
    }

    if (isOpen === true && Inputs.containsKey(keycode, [ Inputs.KEY_ARROW_UP, Inputs.KEY_ARROW_DOWN ])) {
      // Up and down arrows

      let focusedElement = this._suggestionList.querySelector(`.${CLASS_HOVER}`)!
      if (focusedElement) {
        removeClass(focusedElement, CLASS_HOVER)

        const children = Array.prototype.slice.call(this._suggestionList.childNodes) as Element[]

        const totalNodes = children.length - 1
        const direction = keycode === Inputs.KEY_ARROW_UP ? -1 : 1

        let index = children.indexOf(focusedElement)

        index = Math.max(Math.min(index + direction, totalNodes), 0)
        focusedElement = this._suggestionList.childNodes[index] as Element

      } else {
        focusedElement = this._suggestionList.querySelector("li") as Element
      }

      addClass(focusedElement, CLASS_HOVER)
      preventDefault(evt)
      return
    }
  }

  protected _handleWindowClick(event: MouseEvent) {
    if (this._isDropdownTarget(event.target as Node)) {
      return
    }

    this.close()
  }

  protected _selectItem(item?: Element | null) {
    if (!item) {
      return
    }

    const text = item.getAttribute(ATTRIBUTE_VALUE)
    if (text) {
      this._input.value = text

      // Dispatch the changed event
      this.dispatchEvent("change")
    }

    this.close()
  }

  protected _isDropdownTarget(target: Node) {
    let current = target
    while (current !== this._dropdown && current.parentNode) {
      current = current.parentNode
    }

    return current === this._dropdown
  }

  protected _clearSuggestions() {
    // Clear the dropdown item
    empty(this._dropdown)

    this._suggestionList = document.createElement("ul")
    this._dropdown.appendChild(this._suggestionList)
  }

  protected _addSuggestion(text: string, term: string) {
    const escapedTerm = term.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
    const html = text.replace(new RegExp(`(${escapedTerm})`, "gi"), "<strong>$1</strong>")

    const textElement = new DomElement("span")
      .setHtml(html, true)

    const innerElement = new DomElement("div")
      .addClass(CLASS_RESULT)
      .appendChild(textElement)

    const liElement = new DomElement("li")
      .setAttribute(ATTRIBUTE_VALUE, text)
      .appendChild(innerElement)

    this._suggestionList.appendChild(liElement.element)
  }

  protected _getSuggestion(term: string) {
    if (!this._source) {
      throw new Error("The source function is undefined, cannot load suggestions")
    }

    this._source(term, (matches, termused) => {
      this._onMatchesReceived(matches, termused)
    })
  }

  protected _onMatchesReceived(matches: string[], term: string) {
    this._clearSuggestions()

    if (!matches || matches.length === 0) {
      this.close()
    } else {
      // Clear the dropdown item
      empty(this._suggestionList)

      for (let match of matches) {
        this._addSuggestion(match, term)
      }

      this.open()
    }
  }
}

/**
 * Change event
 *
 * @event Autocomplete#change
 * @type {object}
 */

export function init() {
  searchAndInitialize<HTMLElement>(".input-field--autocomplete", (e) => {
    new Autocomplete(e)
  })
}

export default Autocomplete
