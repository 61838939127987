
export function addClass(element: Element, name: string) {
  if (typeof name !== "string") {
    throw new Error("Expected string class name")
  }

  element.classList.add(name)
}

export function removeClass(element: Element, name: string) {
  if (typeof name !== "string") {
    throw new Error("Expected string class name")
  }

  element.classList.remove(name)
}

export function hasClass(element: Element, name: string) {
  if (typeof name !== "string") {
    throw new Error("Expected string class name")
  }

  return element.classList.contains(name)
}

export function toggleClass(element: Element, name: string) {
  if (typeof name !== "string") {
    throw new Error("Expected string class name")
  }

  element.classList.toggle(name)
}

/**
 * Determines if the given element is hidden from view.
 * @param {Element} Element The dom element to check.
 * @param {boolean} includeParents If set to `true` searches up the DOM and checks parent visibility as well. Defaults to `false`.
 */
export function isHidden(element: HTMLElement, includeParents = false): boolean {
  if (includeParents === false) {
    const style = window.getComputedStyle(element)
    return (style.display === "none") || element.offsetLeft < 0
  }

  let result
  // tslint:disable-next-line:no-conditional-assignment
  while ((result = isHidden(element, false)) === false && element.parentElement) {
    element = element.parentElement
  }

  return result
}

/**
 * Gets the text of an element an makes sure this works on all browsers.
 */
export function text(element: Element) {
  return element.textContent || (element as HTMLElement).innerText
}

export function parentWithClass<E extends Element = Element>(element: E, className: string): E | undefined {
  let current: E = element

  while (!hasClass(current, className) && current.parentElement) {
    current = current.parentElement as Element as E
  }

  if (hasClass(current, className)) {
    return current
  }

  return undefined
}

export function textWidth(text: string, font: string) {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  context!.font = font
  const metrics = context!.measureText(text)
  return Math.round(metrics.width)
}

export function css(element: Element, property: string) {
  return window.getComputedStyle(element).getPropertyValue(property)
}

/**
 * Gets the single element referenced in an items data-* attribute.
 * @param {DomElement} element - The element containing the reference attribute.
 * @param {string} attribute - The name of the reference attribute.
 * @returns {DomElement} The referenced element; or `undefined` if the reference is invalid
 * or the attribute could not be found.
 */
export function getAttributeReference<I extends Element = Element, O extends Element = I>(element: I, attribute: string): O | null | undefined {
  const attrValue = element.getAttribute(attribute)

  if (!attrValue || attrValue === "") {
    return undefined
  }

  return document.querySelector(attrValue) as Element as O
}

/**
 * Gets the document root element (normally the body element)
 * If the document uses a sdx-container wrapper this is returned instead.
 * @returns {Element} The root dom element.
 */
export function getRootElement() {
  let element = document.querySelector(".sdx-container")
  if (!element) {
    element = document.body
  }

  return element
}

/**
 * Removes all child nodes from the provided element.
 * @param {Element} element The Dom element
 */
export function empty(element: Element) {
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }
}
