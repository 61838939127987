import DomElement from "../DomElement"
import { searchAndInitialize } from "../Utils"
import { text } from "../DomFunctions"
import { createLegendItem, isColor, removeAllChildren, ChartLabel } from "./ChartFunctions"

import anime from "animejs"

const QUERY_DATA_CATEGORIES = ".js-data-list .js-category"
const QUERY_DATA_ITEMS = ".js-data-list .js-data"
const QUERY_CHART = ".js-chart"
const QUERY_LEGEND = ".bar-chart__legend"

const CLASS_INDICATOR = "indicator"
const CLASS_LABEL_X = "axis-x-label"
const CLASS_INDICATOR_WRAPPER = "indicator-wrapper"
const CLASS_INDICATOR_INNER_WRAPPER = "indicator-wrapper-inner"
const CLASS_INDICATOR_EMPTY = "empty"

const CLASS_TOOLTIP = "tooltip"
const CLASS_TOOLTIP_LEFT = "tooltip--left"
const CLASS_TOOLTIP_RIGHT = "tooltip--right"
const CLASS_TOOLTIP_MULTILINE = "tooltip--multiline"

const ANIMATION_DURATION = 500

export interface Category extends ChartLabel {
}

export interface DataEntry {
  title: string
  class: string
  values: number[]
}

export interface ChartData {
  categories: Category[]
  items: DataEntry[]
}

/**
 * Bar Chart Horizontal Component.
 */
class BarChartVertical extends DomElement<HTMLElement> {
  private _data!: ChartData

  private _unit!: string
  private _maxValue!: number

  private _chart!: HTMLElement
  private _legend!: HTMLElement

  /**
   * Creates and initializes the bar chart horizontal component.
   * @param element - root element of the chart.
   * @param data - data for the chart.
   */
  constructor(element: HTMLElement, data?: ChartData) {
    super(element)

    if (data) {
      this._data = data
    }

    this._initialize()
  }

  protected _initialize() {
    this._unit = this.getAttribute("data-unit") || ""

    this._maxValue = parseFloat(this.getAttribute("data-max")!) || 100

    this._chart = this.element.querySelector(QUERY_CHART)! as HTMLElement
    this._legend = this.element.querySelector(QUERY_LEGEND)! as HTMLElement

    if (!this._data) {
      this._data = this._tryGetData(this.element)
    }

    this._render()
  }

  protected _tryGetData(element: HTMLElement): ChartData {
    const data: ChartData = {
      categories: [],
      items: []
    }

    const categories = element.querySelectorAll(QUERY_DATA_CATEGORIES)
    const items = element.querySelectorAll(QUERY_DATA_ITEMS)

    for (const category of categories) {
      data.categories.push(
        {
          title: text(category),
          color: category.getAttribute("data-color")!
        }
      )
    }

    for (const item of items) {
      const dataEnty: DataEntry = {
        title: text(item),
        class: item.getAttribute("data-class")!,
        values: []
      }

      const vals = item.getAttribute("data-value")
      if (vals) {
        for (const val of vals.split(",")) {
          dataEnty.values.push(parseFloat(val))
        }
      }

      data.items.push(dataEnty)
    }

    return data
  }

  protected _getTooltipContent(entry: DataEntry, categories: Category[]) {
    let tooltip = ""
    for (let i = 0; i < entry.values.length; i++) {
      tooltip += `${categories[i].title}: ${entry.values[i]} ${this._unit}\n`
    }

    return tooltip.trim()
  }

  protected _render() {
    if (this._legend) {
      removeAllChildren(this._legend)

      for (const category of this._data.categories) {
        const legendItem = createLegendItem(category)
        this._legend.appendChild(legendItem)
      }
    }

    removeAllChildren(this._chart)

    const animationStages: Element[][] = []

    let leftSideItems = Math.floor(this._data.items.length / 2)

    for (const item of this._data.items) {
      let element = new DomElement("li")

      if (item.class) {
        element.addClass(item.class)
      }

      const listElement = new DomElement("ul")
        .addClass(CLASS_INDICATOR_WRAPPER)

      const wrapper = new DomElement("div")
        .addClass(CLASS_INDICATOR_INNER_WRAPPER)
      listElement.appendChild(wrapper)

      element.appendChild(listElement)

      const tooltip = this._getTooltipContent(item, this._data.categories)
      if (tooltip) {
        wrapper
          .addClass(CLASS_TOOLTIP)
          .addClass(leftSideItems <= 0 ? CLASS_TOOLTIP_LEFT : CLASS_TOOLTIP_RIGHT)
          .setAttribute("aria-label", tooltip)

        if (item.values.length > 1) {
          wrapper.addClass(CLASS_TOOLTIP_MULTILINE)
        }
      }

      for (let i = 0; i < item.values.length; i++) {
        const height = (this._chart.offsetHeight / this._maxValue) * item.values[i]

        const indicator = new DomElement("li")
          .addClass(CLASS_INDICATOR)
          .setAttribute("style", `height: ${height}px;`)

        if (height > 0) {
          const color = this._data.categories[i].color

          if (isColor(color)) {
            indicator.setAttribute("style", `background-color: ${color};`)
          } else {
            indicator.addClass(color)
          }

          if (!animationStages[i]) {
            animationStages[i] = []
          }

          animationStages[i].push(indicator.element)
        } else {
          indicator.addClass(CLASS_INDICATOR_EMPTY)
        }

        wrapper.appendChild(indicator)
      }

      const titleDomElement = new DomElement("div")
        .addClass(CLASS_LABEL_X)
      const titleElement = titleDomElement.element as HTMLElement
      titleElement.innerText = item.title
      element.appendChild(titleDomElement)

      this._chart.appendChild(element.element)
      leftSideItems -= 1
    }

    for (let i = 0; i < animationStages.length; i++) {
      const offset = ANIMATION_DURATION * i
      this._animateBars(animationStages[i] as HTMLElement[], offset)

      if (this._legend) {
        this._animateLegend(this._legend.children[i] as HTMLElement, offset)
      }
    }
  }

  private _animateBars(bars: HTMLElement[], animationOffset: number) {
    for (let i = 0; i < bars.length; i++) {
      const bar = bars[i]
      const barHeight = bar.style.height
      bar.style.height = "0"
      anime({
        targets: bars[i],
        height: barHeight,
        easing: "easeInOutQuint",
        duration: ANIMATION_DURATION,
        delay: animationOffset
      })
    }
  }

  private _animateLegend(legend: HTMLElement, animationOffset: number) {
    legend.style.opacity = "0"
    anime({
      targets: legend,
      opacity: 1,
      easing: "easeInOutQuint",
      duration: ANIMATION_DURATION,
      delay: animationOffset
    })
  }

  /**
   * Updates the bar chart with the specified data definitions.
   * @param {Array} - bar chart data definitions.
   */
  public update(data: ChartData) {
    if (data) {
      this._data = data
    }

    this._render()
  }

  /**
   * Removes all event handlers and clears references.
   */
  public destroy() {
    (this as any)._data = undefined

    if (this._legend) {
      removeAllChildren(this._legend);
      (this as any)._legend = undefined
    }
  }

  /**
   * @deprecated use destroy() instead.
   * @todo remove in version 2.0.0
   */
  public destory() {
    this.destroy()
  }
}

export function init() {
  searchAndInitialize<HTMLElement>(".bar-chart-vertical", (e) => {
    new BarChartVertical(e)
  })
}

export default BarChartVertical
