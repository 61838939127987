import { searchAndInitialize } from "../Utils"
import DomElement from "../DomElement"

/**
 * Loader bar component
 */
class LoaderBar extends DomElement {
  private progressElement!: DomElement
  private fileNameElement!: DomElement
  private progressLabelElement!: DomElement
  private totalProgressElement!: DomElement

  private value!: number

  /**
   * Creates and initializes the LoaderBar component.
   * @param {Element} - The root element of the LoaderBar component.
   */
  constructor(element: Element) {
    super(element)
    this._initialize()
  }

  /**
   * Initializes the loader bar component.
   * @private
   */
  protected _initialize() {
    this.progressElement = this.find(".indicator") || this
    this.fileNameElement = this.find(".detail > .name")!
    this.progressLabelElement = this.find(".progress")!
    this.totalProgressElement = this.find(".progress > .file-size")!
  }

  /**
   * Gets the current progress value in the range of 0..1.
   */
  get progress() {
    return this.value
  }

  /**
   * Sets the current progress.
   * @param {number} - The progress in the range of 0..1.
   */
  set progress(val) {
    // val = clamp(val, 0, 1)

    let percentage = (val * 100).toFixed(0)

    this.value = val;
    (this.element as HTMLInputElement).value = String(val)

    this.progressElement.setAttribute("style", `width: ${val * 100}%`)

    if (this.progressLabelElement) {
      this.progressLabelElement.element.textContent = `${percentage}%`
    }
  }

  /**
   * Gets the filename.
   * @returns {string} - The filename.
   */
  get filename() {
    if (!this.fileNameElement) {
      return undefined
    }

    return this.fileNameElement.element.textContent || undefined
  }

  /**
   * Sets the filename.
   */
  set filename(val: string | undefined) {
    if (!this.fileNameElement) {
      throw new Error("Cannot set the filename, missing detail element")
    }

    this.fileNameElement.setHtml(val || "")
  }

  /**
   * Sets the file size label.
   */
  set fileSize(val: string) {
    if (!this.totalProgressElement) {
      throw new Error("Cannot set the fileSize, missing detail element")
    }

    this.totalProgressElement.setHtml(val)
  }
}

export function init() {
  searchAndInitialize(".loader-bar", (e) => {
    new LoaderBar(e)
  })
}

export default LoaderBar
