import * as Dom from "./DomFunctions"

let htmlEvents: {
  [eventName: string]: () => void;
}

/**
 * A wrapper class for DOM Elements.
 */
class DomElement<T extends Element = Element> {
  public element: T
  /**
   * Creates a new instance.
   * @param {Element} - The element to wrap.
   * @param {String} - The DOM element to create.
   */
  constructor(element: T | keyof ElementTagNameMap) {
    if (typeof element === "string") {
      this.element = document.createElement(element) as Element as T
    } else {
      this.element = element
    }
  }

  /**
   * Adds the specified CSS class to the element.
   * @param {String} - The class name to add.
   * @return {DomElement} Returns the current instance for fluent chaining of calls.
   */
  public addClass(name: string) {
    Dom.addClass(this.element, name)
    return this
  }

  /**
   * Removes the specified CSS class from the element.
   * @param {String} - The class name to remove.
   * @return {DomElement} Returns the current instance for fluent chaining of calls.
   */
  public removeClass(name: string) {
    Dom.removeClass(this.element, name)
    return this
  }

  public hasClass(name: string) {
    return Dom.hasClass(this.element, name)
  }

  public toggleClass(name: string) {
    Dom.toggleClass(this.element, name)
    return this
  }

  get classes() {
    return this.element.classList
  }

  public setId(id: string) {
    this.element.setAttribute("id", id)
    return this
  }

  get innerText() {
    return Dom.text(this.element)
  }

  get innerHtml() {
    return this.element.innerHTML
  }

  public setHtml(value: string, allowStrongTag = false) {
    function sanitize(dangerousHtmlString: string) {
      // it's not possible to import _escape from lodash here because the "es2015" preset for babelify is needed
      const map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "\"": "&quot;",
        "'": "&#x27;",
        "`": "&grave;",
        "/": "&#x2F;"
      }
      const reg = /[&<>"'`\/]/ig
      return dangerousHtmlString.replace(reg, (match) => (map as any)[match])
    }

    if (typeof value !== "string") {
      throw new Error("Expected HTML string")
    }

    if (allowStrongTag) {
      let sanitizedValue = sanitize(value)
      sanitizedValue = sanitizedValue.replace("&lt;strong&gt;", "<strong>")
      sanitizedValue = sanitizedValue.replace("&lt;&#x2F;strong&gt;", "</strong>")
      this.element.innerHTML = sanitizedValue
    } else {
      this.element.textContent = value
    }

    return this
  }

  public getAttribute(name: string) {
    return this.element.getAttribute(name)
  }

  public setAttribute(name: string, value: string) {
    this.element.setAttribute(name, value)
    return this
  }

  /**
   * Registers an event listener.
   */
  public addEventListener<T extends keyof HTMLElementEventMap>(type: T, listener: (e: Event) => void) {
    this.element.addEventListener(type, listener)
  }

  /**
   * Unregisters an event listener on the component.
   */
  public removeEventListener<T extends keyof HTMLElementEventMap>(type: T, listener: (e: Event) => void) {
    this.element.removeEventListener(type, listener)
  }

  public appendChild(newChild: DomElement) {
    if (!(newChild instanceof DomElement)) {
      throw new Error("Only other DomElements can be added as children")
    }

    this.element.appendChild(newChild.element)
    return this
  }

  public prependChild(newChild: DomElement) {
    if (!(newChild instanceof DomElement)) {
      throw new Error("Only other DomElements can be added as children")
    }

    this.element.insertBefore(newChild.element, this.element.firstChild)
    return this
  }

  public insertBefore(newChild: DomElement) {
    if (!(newChild instanceof DomElement)) {
      throw new Error("Only other DomElements can be added as children")
    }
    if (!this.element.parentNode) {
      throw new Error("Element is not attached")
    }

    this.element.parentNode.insertBefore(newChild.element, this.element)
    return this
  }

  public insertAfter(newChild: DomElement) {
    if (!(newChild instanceof DomElement)) {
      throw new Error("Only other DomElements can be added as children")
    }
    if (!this.element.parentNode) {
      throw new Error("Element is not attached")
    }

    this.element.parentNode.insertBefore(newChild.element, this.element.nextSibling)
    return this
  }

  public removeChild(oldChild: DomElement) {
    if (!(oldChild instanceof DomElement)) {
      throw new Error("Only a DomElements child can be removed")
    }

    this.element.removeChild(oldChild.element)
  }

  public find(selectors: string) {
    let e = this.element.querySelector(selectors)
    if (e) {
      return new DomElement(e as Element)
    }

    return undefined
  }

  public wrapWithElement(wrapperElement: DomElement) {
    if (!this.element.parentNode) {
      throw new Error("Element is not attached")
    }
    this.element.parentNode.replaceChild(wrapperElement.element, this.element)
    wrapperElement.element.appendChild(this.element)

    return this
  }

  public dispatchEvent(eventName: string) {
    let event
    let el = this.element

    if (document.createEvent) {
      event = document.createEvent("HTMLEvents")
      event.initEvent(eventName, true, true)
    } else if ((document as any).createEventObject) { // IE < 9
      event = (document as any).createEventObject()
      event.eventType = eventName
    }
    event.eventName = eventName
    if (el.dispatchEvent) {
      el.dispatchEvent(event)
    } else if ((el as any).fireEvent && htmlEvents[`on${eventName}`]) { // IE < 9
      (el as any).fireEvent(`on${event.eventType}`, event) // can trigger only real event (e.g. 'click')
    } else if (el[eventName as keyof Element]) {
      (el as any)[eventName]()
    } else if (el[`on${eventName}` as keyof Element]) {
      (el as any)[`on${eventName}`]()
    }
  }

  public css(property: string) {
    return Dom.css(this.element, property)
  }

  /**
   * Removes all child nodes of the current DomElement.
   */
  public empty() {
    Dom.empty(this.element)
  }
}

export default DomElement
